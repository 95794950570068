import React from 'react';
import { Heading, Article, ArticleColumn, TextAccent, ArrowExternal, Link } from '../../components';
import { Stat } from './About.style';

/**
 * About: Highlights composition
 */
const Highlights = () => {
  const yearInMs = 365 * 24 * 60 * 60 * 1000;
  const year = Math.ceil((Date.now() - new Date(2013, 11, 1)) / yearInMs);

  return (
    <>
      <Article variation="bt">
        <Heading element="h3" size="h2" weight={500}>
          More than {year} years of experience
        </Heading>
        <TextAccent>
          Across development, product design, branding, I helped enhance numerous brands and ensure the immersive
          experience for their users at every touchpoint. Check out interesting{' '}
          <Link to="/work">projects I’ve worked on</Link>.
        </TextAccent>
      </Article>
      <Article variation="1/3">
        <ArticleColumn>
          <Heading element="h3" size="h2" weight={500}>
            Products launched:
          </Heading>
          <Stat>
            <ArrowExternal size={70} />
            <br /> 50
          </Stat>
        </ArticleColumn>
        <ArticleColumn>
          <Heading element="h3" size="h2" weight={500}>
            Continuous <br />
            optimisation
          </Heading>
          <TextAccent>
            There is no perfect formula for building a good product: it’s all about listening to your customers, working
            iteratively, and testing continuously.
          </TextAccent>
        </ArticleColumn>
      </Article>
    </>
  );
};

export default Highlights;
