import React from 'react';
import { Emphasis, ContactCTA, WaveWrapper } from './About.style';
import { Article, TextAccent, Button, List, ListItem } from '../../components';
import wave from '../../images/wave.svg';

/**
 * About: Services composition
 */
const Services = () => (
  <Article>
    <Emphasis>
      <div>
        <TextAccent element="h4" weight={700} withSpacing>
          I can help with:
        </TextAccent>
        <List listKind="emDash">
          <ListItem>Web app development</ListItem>
          <ListItem>React Native development</ListItem>
          <ListItem>Consulting</ListItem>
          <ListItem>Design systems</ListItem>
          <ListItem>Prototyping and ideation</ListItem>
          <ListItem>User experience design</ListItem>
          <ListItem>End-to-end/custom solutions</ListItem>
        </List>
      </div>
      <ContactCTA>
        <WaveWrapper>
          <img src={wave} alt="Waving emoji" />
        </WaveWrapper>
        <TextAccent withSpacing>
          <strong>Get in touch</strong> — If you think we can be a good fit, feel free to reach out. I have plenty of
          ideas on how to use creative technology and make your product better.
        </TextAccent>
        <Button to="mailto:danijel.grabez@gmail.com?subject=Let’s%20work%20together" title="Let’s work together" />
      </ContactCTA>
    </Emphasis>
  </Article>
);

export default Services;
