import React from 'react';
import { Article, TextAccent } from '../../components';
import { Header } from './About.style';

/**
 * About: AboutMe composition
 */
const AboutMe = () => (
  <>
    <Header>
      <TextAccent size="large" weight={500}>
        I am a software developer and product designer{' '}
        <TextAccent element="span" size="large" colorKind="neutral" weight={500}>
          who enjoys building beautiful and performant experiences for mobile and web.
        </TextAccent>
      </TextAccent>
    </Header>
    <Article>
      <TextAccent withSpacing>
        I have been fortunate enough to get myself involved in many areas of digital business. I am equipped with a
        particular set of skills which includes a mixture of design, development and product management.
      </TextAccent>
      <TextAccent withSpacing>
        I believe in a culture of experimentation, and looking at the challenges from a different perspective. This
        enables me to form critical thinking which drives my work in many exciting directions, with a clear aim to
        deliver the best experience for the end-user.
      </TextAccent>
      <TextAccent>
        I have been working with many international clients, in small and large organisations, on-site and remotely. I
        am currently based in Berlin 🐻. Originally I am coming from Novi Sad, Serbia 🇷🇸.{' '}
      </TextAccent>
    </Article>
  </>
);

export default AboutMe;
