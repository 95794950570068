/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Page from '../templates/Page';
import Intro from '../compositions/About/Intro';
import AboutMe from '../compositions/About/AboutMe';
import Services from '../compositions/About/Services';
import Highlights from '../compositions/About/Highlights';
import Collaboration from '../compositions/About/Collaboration';
import Testimonials from '../compositions/About/Testimonials';
import { SEO, Section } from '../components';

/**
 * About page
 */
const About = ({ path }) => {
  return (
    <Page preContent={<Intro />}>
      <SEO title="Where can I help | Danijel Grabež" pagePath={path} />
      <Section title="About me">
        <AboutMe />
        <Services />
        <Highlights />
        <Collaboration />
        <Testimonials />
      </Section>
    </Page>
  );
};

About.propTypes = {
  path: PropTypes.string.isRequired,
};

export default About;
