import React from 'react';
import { Heading, Article, TextAccent } from '../../components';
import { Header, CollabList, CollabItem } from './About.style';

/**
 * About: Collaboration composition
 */
const Collaboration = () => (
  <Article>
    <Header>
      <TextAccent size="large" weight={500}>
        We can work together{' '}
        <TextAccent size="large" weight={500} colorKind="neutral" element="span">
          in one of the following models, depending on the project needs.
        </TextAccent>
      </TextAccent>
    </Header>
    <CollabList>
      <CollabItem>
        <Heading element="h3" weight={400}>
          Short term
        </Heading>
        <TextAccent colorKind="neutral">
          Perfect for quick turnaround, when you need someone to get the work done.
        </TextAccent>
      </CollabItem>
      <CollabItem>
        <Heading element="h3" weight={400}>
          Long term
        </Heading>
        <TextAccent colorKind="neutral">
          Encapsulate your next big thing to the next level through continuous development.
        </TextAccent>
      </CollabItem>
      <CollabItem>
        <Heading element="h3" weight={400}>
          Open-source
        </Heading>
        <TextAccent colorKind="neutral">
          Personal, or collaborative development and giving back to the community.
        </TextAccent>
      </CollabItem>
    </CollabList>
  </Article>
);

export default Collaboration;
