import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Intro as IntroComponent } from '../../components';
import { ImageWrapper, Figcaption } from './About.style';

/**
 * About: Intro composition
 */
const Intro = () => {
  const data = useStaticQuery(
    graphql`
      query {
        mural: file(relativePath: { eq: "sprawsm-mural.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <IntroComponent pageTitle="Where can I help">
      <ImageWrapper>
        <Img fluid={data.mural.childImageSharp.fluid} loading="eager" objectFit="cover" alt="Image of Danijel Grabez" />
        <Figcaption>Photo taken in front of Superawesome office in 2015</Figcaption>
      </ImageWrapper>
    </IntroComponent>
  );
};

export default Intro;
